<template>
  <router-view></router-view>
</template>

<script>
	export default {
		name: 'App',
	}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
	margin: 0 !important;
	padding: 0 !important;
}

/* 改变滚动条的颜色 */
* {
	scrollbar-width: thin;
	scrollbar-color: #999 #F5F5F5;
}
*::-webkit-scrollbar {
	background-color: #F5F5F5;
	width: 10px;
}
*::-webkit-scrollbar-thumb {
	background-color: #999;
	border-radius: 5px;
}
 
/* 改变滚动条被悬停时的颜色 */
*::-webkit-scrollbar-thumb:hover {
	background-color: #666;
}
 
/* 改变滚动条的轨道宽度和颜色 */
*::-webkit-scrollbar-track {
	background-color: #F5F5F5;
	border-radius: 5px;
}
.footer{
	margin-top: 3.125rem;
	width: 100%;
	background-color: #f4f4f4;
	padding: 1.875rem 0;
	color: #666666;
	background-color: #ffffff;
}
.footer-main{
	width: 25%;
	display: flex;
	margin:0 auto;
	align-items: center;
	justify-content: center;
}
.footer-main div{
	flex: 1;
}
.footer-main div img{
	width: 1.625rem;
	height: 1.625rem;
}
.copyright{
	margin-top: 1.875rem;
}
* {
    scrollbar-width: auto;
    scrollbar-color: #999 #F5F5F5;
}
.empty{
	width: 80%;
	padding: 6.25rem 0;
	margin: 0 auto;
}
.empty img{
	display: block;
	margin: 0 auto  0.625rem;
	width: 3.75rem;
	height: 3.75rem;
}
.empty div{
	font-size: 14px;
	color: #666666;
	text-align: center;
}
</style>
