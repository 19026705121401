import axios from 'axios'
import CryptoJS from '../node_modules/crypto-js/crypto-js.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

console.log(ElementPlus)

const baseUrl = 'https://www.tianmaohk.shop/Api/'

const cryptKey = 'eshop#/*20240728'
const cryptIv  = 'eshop#/*20240728'

// 超时设置，若30秒之后还在加载中，终止或跳转 
axios.defaults.timeout = 30000
// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    //可以写if判断，提前拦截错误信息
    if (response.status == 200) {
      return response;
	}
  }, function (err) {
    return Promise.reject(err)
})


// 加密
const encryptDes = (data) => {
	// 将key解析为字节
	let aes_key = CryptoJS.enc.Utf8.parse(cryptKey);
	// 将iv解析为字节
	let new_iv = CryptoJS.enc.Utf8.parse(cryptIv);
	// AES加密 CBC模式 ZeroPadding
	let encrypted = CryptoJS.AES.encrypt(data, aes_key, {
		iv: new_iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	})
	// 返回字符串
	return encrypted.toString();
}

//解密
const decryptDes = (data) => {
	let aes_key = CryptoJS.enc.Utf8.parse(cryptKey);
	let aes_iv = CryptoJS.enc.Utf8.parse(cryptIv);
	// 将数据编码成Base64格式
	let baseResult = CryptoJS.enc.Base64.parse(data);
	let ciphertext = CryptoJS.enc.Base64.stringify(baseResult);
	// AES解密 CBC模式 ZeroPadding
	let decryptResult = CryptoJS.AES.decrypt(ciphertext, aes_key, {
		iv: aes_iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	// 返回字符串
	let resData = decryptResult.toString(CryptoJS.enc.Utf8).toString();
	return resData;
}

/*POST*/
const apiPost = (url, params = {}) => {
	let data = encryptDes(JSON.stringify(params))
	let authorization = localStorage.getItem('authorization') ? localStorage.getItem('authorization') : ''
	return new Promise((resolve, reject) => {
		axios({
			method: 'post',
			url:baseUrl + url,
			data: {data: data},
			headers:{
				'Accept': 'application/json',
				'Content-type': 'application/json',
				'authorization': authorization,
			}
		}).then(res => {
			let respon = {
				code: res.data.code,
				data: res.data.data.length > 0 ? JSON.parse(decryptDes(res.data.data)) : [], 
				msg: res.data.msg
			}
			//console.log('res', respon)
			resolve(respon)
		}).catch(err =>{
			reject(err.data)
		})
	})
}

export default {
	apiPost: apiPost,
	decryptDes: decryptDes,
}