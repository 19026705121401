import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index.js"

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Api from './api.js'

const app = createApp(App)
app.config.globalProperties.$api = Api
app.config.globalProperties.$baseUrl = 'https://www.tianmaohk.com'
app.use(router)
app.use(ElementPlus)
app.use(Api)
app.mount('#app')

