import { createRouter, createWebHashHistory } from "vue-router"
const routes = [
	{
		path: "/",
		name: "IndexPage",
		component: () => import("../pages/Index.vue"),
	},
	{
		path: "/Search",
		name: "SearchPage",
		component: () => import("../pages/Search.vue"),
	},
	{
		path: "/Goods",
		name: "GoodsPage",
		component: () => import("../pages/Goods.vue"),
	},
	{
		path: "/Address",
		name: "AddressPage",
		component: () => import("../pages/Address.vue"),
	},
	{
		path: "/Carts",
		name: "CartsPage",
		component: () => import("../pages/Carts.vue"),
	},
	{
		path: "/Pays",
		name: "PaysPage",
		component: () => import("../pages/Pays.vue"),
	},
	{
		path: "/Order",
		name: "OrderPage",
		component: () => import("../pages/Order.vue"),
	},
	{
		path: "/Mores",
		name: "MoresPage",
		component: () => import("../pages/Mores.vue"),
	},
	{
		path: "/Bank",
		name: "BankPage",
		component: () => import("../pages/Bank.vue"),
	}
]

let router = createRouter({
  history: createWebHashHistory(),
  routes,
})
export default router